import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import SideBar from "../../component/sidebar";
import Reports from "../reports";
import BulkSMSForm from "../../component/bulkSmsForm";
import ScheduleReport from "../../containers/scheduleReport";
import Accounts from "../accounts";
import Senders from "../senders";
import ApiIntegration from "../apiIntegration";
import Dashboard from "../dashboard";
import Transaction from "../transactionReport";
import PackagesHistory from "../packagesHistory";
import UpdateAccount from "../updateAccount";
import Contacts from "../contacts";
import TermsAndConditions from "../termsAndConditions";
import AllCampaigns from "../allCamigns";
import DLRTable from "../DLRTable";
import APIReport from "../APITableReport";
import AllOperators from "../AllOperatorReport";
import ReportPerOperator from "../reportsPerOperator"
import BusinessSector from "../BusinessSector";
import PriceList from "../PriceList";
import { useTranslation } from "react-i18next";
import { BulkSMSForm_SAMPLE, OneToOneSMSForm_SAMPLE } from "../../API";
import "./style.css";

export default function Home() {
  const [sideBarCollapsed, toggleSideBar] = useState(false);
  const { i18n } = useTranslation();
  return (
    <div className={`wrapper ${i18n.language}`}>
      <SideBar collapsed={sideBarCollapsed} />

      <div id="content" className="container-fluid">


        <Routes>
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route
            path="/"
            element={
              <BulkSMSForm
                sampleURL={
                  OneToOneSMSForm_SAMPLE
                }
              />
            }
          />
          <Route path="/Reports" element={<Reports />} />
          <Route
            path="/SendBulkSms"
            element={
              <BulkSMSForm
                smsType={"1"}
                sampleURL={
                  BulkSMSForm_SAMPLE
                }
              />
            }
          />
          <Route path="/Accounts" element={<Accounts />} />
          <Route path="/senders" element={<Senders />} />
          <Route path="/Contacts" element={<Contacts />} />
          <Route path="/Transaction" element={<Transaction />} />
          <Route path="/packagesHistory" element={<PackagesHistory />} />
          <Route path="/ApiIntegration" element={<ApiIntegration />} />
          <Route path="/updateAccount" element={<UpdateAccount />} />
          <Route path="/ScheduleReport" element={<ScheduleReport />} />
          <Route path="/termsAndConditions" element={<TermsAndConditions />} />
          <Route path="/AllCampaigns" element={<AllCampaigns />} />
          <Route path="/DLRTable/:ID" element={<DLRTable />} />
          <Route path="/BusinessSector" element={<BusinessSector />} />
          <Route path="/PriceList" element={<PriceList />} />
          <Route path="/APIReport/" element={<APIReport />} />
          <Route path="/ReportPerOperator" element={<ReportPerOperator />} />
          <Route path="/AllOperators" element={<AllOperators />} />
        </Routes>
      </div>
    </div>
  );
}
