import React, {
  useState,
  useMemo,
  useCallback,
  useContext,
} from "react";
import {
  GetAllOperatorsReport,
  GetDetailsReportAllOperators
} from "../../API";
import Table from "../../component/newTable";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import Header from "../../component/header";
import DateRangePicker from "../../component/dateRangePicker";
import StatisticsCard from "../../component/statisticsCard";
import { Pie } from "react-chartjs-2";
import "./style.css";

export default function AllOperatorReport() {
  const { role } = useContext(Context);
  const [data, setData] = useState([]);
  const { startDate, endDate } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [toggleSideBar] = useState(false);
  const { t } = useTranslation();


  const columns = useMemo(
    () => [

      {
        Header: t("Gateway"),
        accessor: "GatWay",
      },
      {
        Header: t("Root"),
        accessor: "Root",
      },
      {
        Header: t("totalSms"),
        accessor: "total",
      },
    ],
    [t]
  );

  const calculateGatewayTotals = (data) => {
    const gatewayTotals = {};
    data.forEach((entry) => {
      const { GatWay, total } = entry;
      if (!gatewayTotals[GatWay]) {
        gatewayTotals[GatWay] = 0;
      }
      gatewayTotals[GatWay] += total;
    });

    return gatewayTotals;
  };
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat().format(number);
  };
  const gatewayTotals = calculateGatewayTotals(data);
  let chartData = {
    labels: Object.keys(gatewayTotals),
    datasets: [
      {
        data: Object.values(gatewayTotals),
        backgroundColor: ["#080808", "#5C5C5C", "#9C9C9C"],
        hoverBackgroundColor: ["#080808", "#5C5C5C", "#9C9C9C"]
      },
    ],
  };

  const [options, setOptions] = useState({
    aspectRatio: 1,
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
        labels: {
          generateLabels: function (chart) {
            const data = chart.data;
            return data.labels.map((label, i) => {
              const total = data.datasets[0].data[i];
              return {
                text: `${label}: ${total.toLocaleString()}`,
                fillStyle: data.datasets[0].backgroundColor[i],
                strokeStyle: data.datasets[0].backgroundColor[i],
              };
            });
          },
          boxWidth: 5,
          boxHeight: 5,
          usePointStyle: true,
          pointStyle: "circle",
          paddingTop: 5,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const gateway = tooltipItem.label;
            const total = tooltipItem.raw;
            return `${gateway}: ${total.toLocaleString()}`;
          },
        },
      },
    },
  })
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setIsLoading(true);
      setData([]);
      chartData = [];
      GetAllOperatorsReport({
        "@page": pageIndex,
        "@rowsPerPage": pageSize,
        "@startDate": new Intl.DateTimeFormat("en").format(startDate),
        "@endDate": new Intl.DateTimeFormat("en").format(endDate),
      })
        .then((response) => {
          setIsLoading(false);
          setData(response.data.table1);
          setPageCount(response.data.table2[0].Total);
        })
        .catch((error) => {
          setIsLoading(false);
        });

    },
    [startDate, endDate, t, role]
  );


  const exportHandlerAdmin = () => {
    setIsLoadingBtn(true);
    GetDetailsReportAllOperators({
      "@startDate": new Intl.DateTimeFormat("en").format(startDate),
      "@endDate": new Intl.DateTimeFormat("en").format(endDate),
    })
      .then((response) => {
        setIsLoadingBtn(false);
        let link = document.createElement("a");

        link.download = "Report";
        link.href =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          response.data;

        if (/android|iPad|iPhone|iPod/i.test(navigator.userAgent))
          link.download += ".xlsx";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setIsLoadingBtn(false);
      });
  };
  return (
    <div className="allGatewaysReport">
      <Header
        title={t("AllGateway")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />
      <div className="tableMainTitles">
        <DateRangePicker />
        <button
          className="exportBtn"
          onClick={() => exportHandlerAdmin()}
          disabled={isLoadingBtn || isLoading}
        >
          {t("exportReport")}
          {isLoadingBtn && (
            <span className="spinner-border" role="status"></span>
          )}
        </button>
      </div>

      <div className="gatewaysStatisticsContainer">
        <div className="statisticsCardsMainDiv">
          <StatisticsCard icon={"images/smsLogo.png"} value={data.length !== 0 && formatNumberWithCommas(gatewayTotals.Vodafone)} loading={isLoading} name={"Vodafone"} unit={t('sms')} />
          <StatisticsCard icon={"images/smsLogo.png"} value={data.length !== 0 && formatNumberWithCommas(gatewayTotals.Etisalat)} loading={isLoading} name={"Etisalat"} unit={t('sms')} />
          <StatisticsCard icon={"images/smsLogo.png"} value={data.length !== 0 && formatNumberWithCommas(gatewayTotals.Orange)} loading={isLoading} name={"Orange"} unit={t('sms')} />
        </div>
        <div className="pieChartCard">
          <span className="mb-2">{t("AllGateway")}</span>
          {isLoading && (
            <span className="spinner-border" role="status"></span>
          )}
          <div className="pieChartContainer">
            <Pie data={chartData} options={options} />
          </div>
        </div>

      </div>

      <div className="tableDiv">
        {" "}
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={isLoading}
          TotalRows={pageCount}
          tableHeader={t("AllGateway")}
        />
      </div>

    </div>
  );
}
