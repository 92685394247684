import React from "react";
import { useTranslation } from "react-i18next";
import "./style.css";


export default function StatisticsCard({ icon, value, loading, name, unit }) {
  const { t } = useTranslation();
  return (
    <div className="statisticsCardStyle">
      <span className="statisticsCardIconContainer">
        <img
          className="cardsIcons"
          src={icon}
          alt="card icon"
        />
      </span>
      <div className="statisticsCardContent">
        <span className="statisticsCardTitle">{name}</span>
        {loading && (
          <span className="spinner-border" role="status"></span>
        )}
        <span className="statisticsCardValue">
          {value && <> {value} {unit}</>}
        </span>
      </div>
    </div>
  );
}
