import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  Popover,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Context } from "../../context";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./style.css";

const styles = {
  calendarWrapper: {
    padding: "16px",
  },
};

const DateRangePicker = ({ classes }) => {
  const dateFormat = "DD/MM/YYYY";
  const firstDay = new Date();
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { startDate, endDate, setStartDate, setEndDate } = useContext(Context);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fromDate, setFromDate] = useState(startDate);
  const [toDate, setToDate] = useState(endDate);
  const { t } = useTranslation();
  const onAdornmentClick = (e) => {
    setDisplayCalendar(true);
    setAnchorEl(e.currentTarget);
  };

  const onInputChange = (e) => {
    const value = e.target.value;
    const { fromDate, toDate } = processInputValue(value);

    setInputValue(value);
    setFromDate(fromDate);
    setToDate(toDate);
  };

  const onPopoverClose = (e, reason) => {
    setStartDate(fromDate);
    setEndDate(toDate);
    if (reason === "backdropClick") {
      // Clicking outside the popover; no date selection occurred
      setDisplayCalendar(false);
      setAnchorEl(null);
    } else {
      setDisplayCalendar(false);
      setAnchorEl(null);
    }
  };

  const onSelectDateRanges = ({ selection }) => {
    let { startDate, endDate } = selection;

    startDate = moment(startDate);
    startDate = startDate.isValid() ? startDate.toDate() : undefined;

    endDate = moment(endDate);
    endDate = endDate.isValid() ? endDate.toDate() : undefined;

    let value = "";
    if (startDate) value += moment(startDate).format(dateFormat);
    if (endDate) value += " - " + moment(endDate).format(dateFormat);

    setInputValue(value);
    setFromDate(startDate);
    setToDate(endDate);
  };

  const processInputValue = (value) => {
    let [fromDate, toDate] = value.split("-").map((elm) => elm.trim());

    fromDate = moment(fromDate, dateFormat);
    fromDate = fromDate.isValid() ? fromDate.toDate() : undefined;

    toDate = moment(toDate, dateFormat);
    toDate = toDate.isValid() ? toDate.toDate() : undefined;

    return { fromDate, toDate };
  };

  const onTextFieldClick = (e) => {
    setDisplayCalendar(true);
    setAnchorEl(e.currentTarget);
  };

  useEffect(() => {
    // Set default value for the input field when component mounts
    const defaultInputValue =
      fromDate && toDate
        ? `${moment(fromDate).format(dateFormat)} - ${moment(toDate).format(
          dateFormat
        )}`
        : "";
    setInputValue(defaultInputValue);
  }, [fromDate, toDate]);

  return (
    <div className="dateRangFieldStyle">
      <TextField
        className="dateRangFieldStyleFonts"
        fullWidth={true}
        value={inputValue}
        onClick={onTextFieldClick}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={onAdornmentClick}>
                <DateRangeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={onInputChange}
      />
      <Popover
        open={displayCalendar}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={onPopoverClose}
      >
        <div className={classes.calendarWrapper}>
          <DateRange
            ranges={[
              {
                startDate: fromDate,
                endDate: toDate,
                key: "selection",
              },
            ]}
            onChange={onSelectDateRanges}
            staticRanges={undefined}
            inputRanges={undefined}
            showMonthAndYearPickers={true}
            moveRangeOnFirstSelection={false}
            showDateDisplay={false}
            scroll={{ enabled: true }}
          />
        </div>
      </Popover>
    </div>
  );
};

export default withStyles(styles, { name: "DateRangePicker" })(DateRangePicker);
