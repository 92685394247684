import React, { useState, useMemo, useCallback, useRef, useContext } from "react";
import { toast } from "react-toastify";
import { Context } from "../../context";
import PopUp from "../../component/popUp";
import Header from "../../component/header";
import {
  AddContactsGroup,
  EditContactsGroup,
  GetContactsGroup,
  GetUserGroupsData,
  BulkSMSForm_SAMPLE,
  UploadSenderFile,
  DeleteContactsGroup

} from "../../API";
import Table from "../../component/newTable";
import Field from "../../component/field";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
import TextArea from "../../component/textArea";
import "./style.css";

export default function Reports() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [groupName, setGroupName] = useState();
  const [editGroupId, setEditGroupId] = useState();
  const [fileName, setFileName] = useState();
  const { reload, setReload } = useContext(Context);
  const [phoneNumbersCount, setPhoneNumbersCount] = useState(0);
  const [phoneNumbersList, setPhoneNumbersList] = useState("");
  const [show, setShow] = useState(false);
  const [showEditGroup, setShowEditGroup] = useState(false);
  const handleClose = () => { setShow(false); setPhoneNumbersList(""); setPhoneNumbersCount(0); };
  const handleCloseEditGroup = () => setShowEditGroup(false);
  const [toggleSideBar] = useState(false);
  const fileRef = useRef();
  const handleFile = () => {
    let file = fileRef.current.files[0];
    if (file) setFileName(file.name);
  };
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: t("groupName"),
        accessor: "groupName",
      },
      {
        Header: t("date"),
        accessor: "date",
      },
      {
        Header: "",
        accessor: "groupFilePath",
        Cell: ({ row: { original } }) => {
          return (
            <div className="contactsActions">
              {/* <i
                title="Download File"
                className="fas fa-file-download downloadIconStyle"
                onClick={() =>
                  window.open(`${original.groupFilePath}`, "_self")
                }
              ></i> */}
              <i
                title="Edit Group"
                className="fas fa-pen editIconStyle"
                onClick={() => handleShowEditGroup(original.id)}
              ></i>
              <i
                title="Delete file"
                className="fas fa-trash deleteIconStyle"
                onClick={() => deleteFile(original.id)}
              ></i>


            </div>
          );
        },
      },
    ],
    [t]
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setIsLoading(true);
      setData([]);
      GetContactsGroup({
        "@page": pageIndex,
        "@rowsPerPage": pageSize,
      })
        .then((response) => {
          setIsLoading(false);
          setData(response.data.table1);
          setPageCount(response.data.table2[0].Total);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [isLoadingBtn, reload]
  );

  const handleOnChangePhoneNumbersListArea = (e) => {
    const inputValue = e.target.value;
    const regex = /^[0-9\n]*$/;

    // if (!regex.test(inputValue)) {
    //   return;
    // }

    const lines = inputValue.split('\n');

    const formattedLines = lines.map(line => {
      let formattedLine = '';
      for (let i = 0; i < line.length; i++) {
        formattedLine += line[i];
        if ((i + 1) % 12 === 0 && i !== line.length - 1) {
          formattedLine += '\n';
        }
      }
      return formattedLine;
    });

    const formattedInputValue = formattedLines.join('\n');

    setPhoneNumbersList(formattedInputValue);

    if (formattedInputValue.trim() === '') {
      setPhoneNumbersCount(0);
      return;
    }

    const nonEmptyLines = formattedInputValue.split('\n').filter(line => line.trim() !== '');

    const totalLines = nonEmptyLines.length;

    setPhoneNumbersCount(totalLines);
  };

  const deleteFile = (id) => {
    console.log(id);
    DeleteContactsGroup({
      "@ID": id,
    })
      .then((response) => {
        toast.success("Group deleted successfully");
        setReload(!reload);
        window.location.reload();
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  const popUpFooter = (
    <button
      className="addGroupFooterBtn"
      disabled={isLoadingBtn}
      onClick={() => save()}
    >
      {isLoadingBtn && <span className="spinner-border" role="status"></span>}
      {t("addGroup")}
    </button>
  );

  const popUpFooterEditGroup = (
    <button
      className="addGroupFooterBtn"
      disabled={isLoadingBtn}
      onClick={() => edit()}
    >
      {isLoadingBtn && <span className="spinner-border" role="status"></span>}
      {t("editGroup")}
    </button>
  );
  const handleShow = () => {
    setShow(true);
    setFileName("");
    setGroupName("");
    setPhoneNumbersList("");
    setPhoneNumbersCount(0);
  };


  const handleShowEditGroup = async (id) => {
    setShowEditGroup(true);
    setEditGroupId(id);
    setFileName("");
    setGroupName("");
    setPhoneNumbersList("");
    setPhoneNumbersCount(0);

    try {
      const response = await GetUserGroupsData({ "@groupID": id });
      setGroupName(response.data.table1[0].groupName);
      const filePath = response.data.table1[0].groupFilePath;
      const result = await editGroupFiles(filePath);
      let formattedPhoneNumbers = '';
      if (typeof result.data === 'string') {
        formattedPhoneNumbers = result.data.split(',').map(number => number.trim()).join('\n');
      } else if (Array.isArray(result.data)) {
        formattedPhoneNumbers = result.data.map(number => number.toString().trim()).join('\n');
      } else {
        console.error('Unexpected data format:', result.data);
      }

      setPhoneNumbersList(formattedPhoneNumbers);

      // Update the phone numbers count
      setPhoneNumbersCount(formattedPhoneNumbers.split('\n').length);
    } catch (error) {
      console.error('Error fetching group data or editing group files:', error);
    } finally {
      setIsLoading(false);
    }
  }






  const uploadFiles = () => {
    let sameNames = true;
    let token = window.localStorage.getItem("token");
    let formData = new FormData();


    console.log(phoneNumbersList);


    formData.append("mobileNumbers", phoneNumbersList);
    formData.append("uploadPath", `\\\\192.186.100.24/Storage/Groups_Excel_Files/`);
    // formData.append("sameNames", sameNames);
    return axios.post(
      "https://hub.advansystelecom.com/generalapiv12/api/bulkSMS/AddGroupList",
      formData,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  };

  const editGroupFiles = (filePath) => {
    let sameNames = true;
    let token = window.localStorage.getItem("token");
    let formData = new FormData();

    let fileInput = filePath;

    if (!fileInput) {
      return Promise.reject(new Error("No file selected."));
    }

    formData.append("file", fileInput);

    return axios.post(
      "https://hub.advansystelecom.com/generalapiv12/api/bulkSMS/EditGroupList",
      formData,
      {
        headers: {
          Authorization: "Bearer " + token
        },
      }
    );
  };



  const save = async () => {

    setIsLoadingBtn(true);
    if (groupName === "" || phoneNumbersCount == 0) {
      toast.error(`${t("addGroupError")}`);
      setIsLoadingBtn(false);
      return;
    }
    var res = await uploadFiles();
    AddContactsGroup({
      "@groupName": groupName,
      "@groupFilePath": res.data,
    })
      .then((response) => {
        toast.success(`${t("GroupAddedSuccessfully")}`);
        setShow(false);
        setReload(!reload);
        setIsLoadingBtn(false);
        setPhoneNumbersList("");
        setPhoneNumbersCount(0);
      })
    handleClose();
    setIsLoadingBtn(false);
    return;
  };

  const edit = async () => {
    setIsLoadingBtn(true);

    if (phoneNumbersList.length == 0 || groupName === "") {
      toast.error(`${t("addGroupError")}`);
      setIsLoadingBtn(false);
      return;
    }

    var res = await uploadFiles();
    EditContactsGroup({
      "@groupName": groupName,
      "@groupFilePath": res.data,
      "@groupID": editGroupId
    })
      .then((response) => {
        toast.success(`${t("groupUpdatedSuccessfully")}`);
        setShowEditGroup(false);
        setIsLoadingBtn(false);

      })
      .catch((error) => {
        setIsLoadingBtn(false);
      });
    handleClose();
    setIsLoadingBtn(false);
    return;

  };

  return (
    <div className="contactsContainer">
      <Header
        title={t("groups")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />
      <div className="tableMainTitles">
        <span className="titleContacts"></span>
        <div className="btnsDiv">
          {/* <button
            className="downloadBtn"
            disabled={isLoading}
            onClick={() =>
              window.open(
                BulkSMSForm_SAMPLE, "_self"

              )
            }
          >
            {t("downloadSample")}
          </button> */}

          <button className="addGroupBtn" onClick={handleShow}>
            {t("addGroup")}
          </button>
        </div>
      </div>
      <div className="tableDiv">
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={isLoading}
          TotalRows={pageCount}
          tableHeader={t("groups")}
        />
      </div>

      <PopUp
        popUpTitle={t("addGroup")}
        popUpFooter={popUpFooter}
        showPopUp={show}
        closePopUp={handleClose}
      >
        <div>
          <div className={`form - group enterDataInput ${i18next.language}`}>
            <Field
              inputFieldValue={groupName}
              setOnChange={setGroupName}
              placeholder={t("enterGroupName")}
            />
          </div>
          <div className="groupNumberListContainer">
            <label className="formLabelStyle">
              {t("phoneNumbersList")}
            </label>
            <TextArea
              classStyle={"groupNumberListStyle"}
              inputFieldValue={phoneNumbersList}
              setOnChange={(e) => handleOnChangePhoneNumbersListArea(e)}
              placeholder={t("phoneNumbersList")}
              inputFieldType={"number"}
            />
            <div className="msgLengthDiv">
              {phoneNumbersCount}
              {t("phoneNumberCounter")}
            </div>
          </div>
          {/* <div className="filesUploadDiv mt-3">
            <div className="formCard">
              <div className="uploadFileIcon">
                <img
                  className="uploadCloud"
                  src="images/upload.png"
                  alt="upload icon"
                />
                <div className="uploadInfo">
                  {t("selectAndUpload")}
                  <span style={{ color: " var(--second-color);" }}>{t("fileName")}</span>
                </div>
                <div className="uploadFileType">
                  {t("supportedFormatesExcel")}
                  <span id="fileName">{"( " + fileName + ") "}</span>
                </div>
              </div>

              <input
                className="inputFilesUpload"
                ref={fileRef}
                id="uploadedFile"
                type="file"
                onChange={handleFile}
                accept=".csv, .xlsx"
              />
            </div>
            {fileName && <div className="uploadProgress mt-2"></div>}
          </div> */}
          <div></div>
        </div>
      </PopUp>

      <PopUp
        popUpTitle={t("editGroup")}
        popUpFooter={popUpFooterEditGroup}
        showPopUp={showEditGroup}
        closePopUp={handleCloseEditGroup}
      >
        <div>
          <div className={`form - group enterDataInput ${i18next.language}`}>
            <Field
              inputFieldValue={groupName}
              setOnChange={setGroupName}
              placeholder={t("enterGroupName")}
            />
          </div>
          <div className="groupNumberListContainer">
            <label className="formLabelStyle">
              {t("phoneNumbersList")}
            </label>
            <TextArea
              classStyle={"groupNumberListStyle"}
              inputFieldValue={phoneNumbersList}
              setOnChange={(e) => handleOnChangePhoneNumbersListArea(e)}
              placeholder={t("phoneNumbersList")}
              inputFieldType={"number"}
            />
            <div className="msgLengthDiv">
              {phoneNumbersCount}
              {t("phoneNumberCounter")}
            </div>
          </div>
        </div>
      </PopUp>
    </div>
  );
}
